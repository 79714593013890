import Table from '@components/Table';
import { useState, useEffect } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import TextField from '@components/TextField';
import Select from '@components/Select';
import PercentIcon from '@assets/images/icons/percent.svg';
import { useHistory } from "react-router-dom";

const Index = (props) => {
  const history = useHistory();
  let [rows] = useState([]);
  let [q, setQ] = useState(null);
  let [type, setType] = useState('all');

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const setSearch = (value) => {
    if (value.length > 2) {
      setQ(value)
      /* setTimeout(() => {
      }, 2000); */
    }
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const typeOptions = [
    {
      title: 'Agencia parceira',
      value: 'agency',
    },
    {
      title: 'Vendedor',
      value: 'seller',
    },
    {
      title: 'Vendedor parceiro',
      value: 'partner',
    }
  ]

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <img
            className="mr-2"
            alt="Select file"
            src={PercentIcon}
            width="24"
          />
          <span>Comissões</span>
        </div>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="flex flex-wrap items-center space-x-3">
          <div className="flex-none">
            <TextField
              className="inputRounded"
              label="Busque por um vendedor ou parceiro"
              width="300px"
              variant="outlined"
              type="text"
              name="q"
              onChange={(e) => setSearch(e.target.value)}
              required
            />
          </div>
          <div className="flex-none">
            <Select
              className="pr-2 inputRounded"
              label="Filtre por tipo"
              width="200px"
              defaultValue="all"
              onChange={(e) => setType(e.target.value === "all" ? null : e.target.value)}
              items={{
                field: {
                  value: 'value',
                  text: 'title'
                },
                data: typeOptions
              }}
            />
          </div>
        </div>
      </div>
      <Table
        path="/sellers/get/all_sellers" // TODO: trocar para a rota de comissão
        params={{
          q, type
        }}
        onRowClick={(props) => {
          const row = props.row;
          history.push(`/admin/comissoes/${row.type}/${row.id}/reservas`)
        }}
        columns={[ // TODO: Ajustar valor dos campos
            {
              headerName: 'Nome',
              headerClassName: 'super-app-theme--header',
              flex: 1,
              field: 'name',
            },
            {
              headerName: 'Tipo',
              headerClassName: 'super-app-theme--header',
              flex: 1,
              field: 'type',
              renderCell: (params) => {
                const row = params.row;
                const sellerType = {
                  seller: {
                    text: 'Vendedor',
                  },
                  partner: {
                    text: 'Vendedor parceiro',
                  },
                  agency: {
                    text: 'Agência parceira',
                  }
                }
                return <span className="flex items-center">
                  {sellerType[row.type].text}
                </span>
              }
            },
            {
              headerName: 'QTD de vendas',
              headerClassName: 'super-app-theme--header',
              field: 'total',
              headerAlign: 'right',
              align: 'right',
              flex: 1,
            },
          ]}
        rows={rows}
      />
    </div>
  </>
}

export default Index;
