import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import { format, isValid } from 'date-fns';
import { makeStyles } from '@material-ui/core'
import SelectSearch from 'react-select'
import { components } from "react-select";
import ChangeStateModal from './partials/ChangeStateModal';
import Icon from '@material-ui/core/Icon';
import Calendar from './partials/Calendar';
import Select from '@components/Select';

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: 15
  }
}))

const Index = (props) => {
  const componentRef = useRef()
  const [rows] = useState([]);

  const [dateProof, setDateProof] = useState(null);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [selectedIdPayment, setSelectedIdPayment] = useState(null);
  const [sellers, setSellers] = useState([]);
  const [seller, setSeller] = useState('');
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState('');
  const [tours, setTours] = useState([]);
  const [tour, setTour] = useState('');

  // const [companies, setCompanies] = useState([]);
  // const [company, setCompany] = useState(null);

  //selects filters
  const [regionsIds, setRegionsIds] = useState('');
  const [sellersIds, setSellersIds] = useState('');
  const [toursIds, setToursIds] = useState('');

  //Menus select
  const [openMenuRegion, setOpenMenuRegion] = useState(false)
  const [openMenuProduct, setOpenMenuProduct] = useState(false)
  const [openMenuSeller, setOpenMenuSeller] = useState(false)

  const [seats, setSeats] = useState([])
  const [reservations, setReservations] = useState([])
  const [tourDates, setTourDates] = useState([])
  const urlSeats = '/cupos_general';

  // const urlCompanies = '/companies';

  const [load, setLoad] = useState(true);

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/fluxo-de-caixa'
    },
  ];

  const getSeats = (url, dataInicio, dataFim) => {
    let data = new Date();
    let primeiro = data.getDate() - data.getDay();
    let dataInicial = new Date(data.setDate(primeiro)).toUTCString();
    dataInicial = (data.getFullYear() + "-" + ((data.getMonth() + 1)) + "-" + (data.getDate()));
    let dataFinal = new Date(data.setDate(data.getDate() + 6)).toUTCString();
    dataFinal = (data.getFullYear() + "-" + ((data.getMonth() + 1)) + "-" + (data.getDate()));
    if (dataInicio === null) {
      dataInicio = dataInicial
    }
    if (dataFim === null) {
      dataFim = dataFinal
    }

    setLoad(true);
    setOpenMenuRegion(false)
    setOpenMenuProduct(false)
    setOpenMenuSeller(false)
    apiClient.httpInstance.get(url, {
      params: {
        tours_ids: toursIds.toString(),
        sellers_ids: sellersIds.toString(),
        regions_ids: regionsIds.toString(),
        // company: company,
        data_inicial: dataInicio,
        data_final: dataFim,
      }
    }).then(response => {
      if (response.status === 200) {
        setReservations(response.data);
        setLoad(false);
      }
    });
  }

  const getRegions = () => {
    apiClient.httpInstance.get(`/regions`).then(response => {
      if (response.status === 200) {
        //let options = [{ value: 'all', name: 'Todas as regiões', label: 'Todas as regiões' }]
        let options = []
        response.data.map((element) => {
          options.push({ value: element.id, name: element.name, label: element.name })
        })
        options = options.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })

        setRegions(options)
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const getSellers = () => {
    apiClient.httpInstance.get(`/users?seller=true&status=true&pageSize=1000`).then(response => {
      if (response.status === 200) {
        //let options = [{ value: '', name: 'Todos os vendedores', label: 'Todos os vendedores' }]
        let options = []
        response.data.data.map((element) => {
          options.push({ value: element.id, name: element.name, label: element.name })
        })
        setSellers(options)
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const getTours = () => {
    apiClient.httpInstance.get(`/tours?pageSize=1000`).then(response => {
      if (response.status === 200) {
        //let options = [{ value: '', name: 'Todos os produtos', label: 'Todos os produtos' }]
        let options = []
        response.data.data.map((element) => {
          options.push({ value: element.id, name: element.name, label: element.name })
        })
        setTours(options)
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const confirmPayment = () => {
    apiClient.httpInstance.put(`/confirm_sale_payment/${selectedIdPayment}`, {
      payment_id: selectedIdPayment
    }).then(response => {
      if (response.status === 200) {
        setOpenModal(false)
        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }

  /* const getCompanies = url => {
    apiClient.httpInstance.get(`/companies?pageSize=500`).then(response => {
      if (response.status === 200) {
        let companies = response.data.data.sort(function (a, b) {
          return a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;
        })

        setCompanies(companies);
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  } */

  useEffect(() => {
    getSeats(urlSeats, null, null)
    getSellers();
    getRegions();
    getTours();
    // getCompanies(urlCompanies);
  }, []);

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  useEffect(() => {
    document.querySelectorAll(".fc-day-today").forEach(el => el.style.backgroundColor = '#fff')
  }, [sellersIds, regionsIds, toursIds]);

  const handleSelect = (ev, type) => {
    setLoad(true);
    let newValues = ev.map((item) => {
      return item.value
    });

    if (type === 'region') {
      if (ev.length === 0) {
        setRegion('')
        setRegionsIds('')

        apiClient.httpInstance.get(urlSeats, {
          params: {
            regions_ids: '',
            tours_ids: toursIds.toString(),
            sellers_ids: sellersIds.toString(),
          }
        }).then(response => {
          if (response.status === 200) {
            setReservations(response.data);
          }
        });

      } else {
        setRegion(ev.name)
        setRegionsIds(newValues)
      }
    }

    if (type === 'tour') {
      if (ev.length === 0) {
        setTour('')
        setToursIds('')

        apiClient.httpInstance.get(urlSeats, {
          params: {
            tours_ids: '',
            sellers_ids: sellersIds.toString(),
            regions_ids: regionsIds.toString(),
          }
        }).then(response => {
          if (response.status === 200) {
            setReservations(response.data);
          }
        });
      } else {
        setTour(ev.name)
        setToursIds(newValues)
      }
    }

    if (type === 'seller') {
      if (ev.length === 0) {
        setSeller('')
        setSellersIds('')

        apiClient.httpInstance.get(urlSeats, {
          params: {
            tours_ids: toursIds.toString(),
            sellers_ids: '',
            regions_ids: regionsIds.toString(),
          }
        }).then(response => {
          if (response.status === 200) {
            setReservations(response.data);
          }
        });
      } else {
        setSeller(ev)
        setSellersIds(newValues)
      }
    }
    setLoad(false);
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            calendar_today
          </Icon>
          <span>Cupos</span>
        </div>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center md:space-x-3">
          {/* <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <Select
              label="Filtrar por empresa"
              defaultValue="all"
              gender="f"
              width="140px"
              onChange={(e) => setCompany(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'surname',
                  key: 'key'
                },
                data: companies
              }}
            ></Select>
          </div> */}
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <div style={SearchStyle1}>
              <span style={LabelStyle}>Busque por região</span>
              <span className='text-capitalize' style={{ position: 'absolute', backgroundColor: 'white', display: openMenuRegion ? 'none' : 'block' }}>{regionsIds?.length > 0 ? `${regionsIds?.length} selecionada` + (regionsIds?.length > 1 ? 's' : '') : '                           '}</span>
              <span style={SpanSelect}>
                <SelectSearch
                  styles={customStyles}
                  placeholder="Todas as regiões"
                  menuIsOpen={openMenuRegion}
                  onMenuOpen={() => setOpenMenuRegion(true)}
                  onMenuClose={() => setOpenMenuRegion(false)}
                  //options={regions}
                  options={regions.map(reg => ({
                    value: reg?.value,
                    name: reg?.name?.toLowerCase(),
                    label: reg?.name?.toLowerCase(),
                  }))}
                  isMulti
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  radioGroup
                  components={{
                    Option, //MenuList: SelectMenuButton
                  }}
                  showSelectedItems={false}
                  width='220px'
                  value={region}
                  onChange={(ev) => handleSelect(ev, 'region')}
                  name="region"
                /></span>
            </div>
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <div style={SearchStyle2}>
              <span style={LabelStyle}>Busque por produtos</span>
              <span style={{ position: 'absolute', backgroundColor: 'white', display: openMenuProduct ? 'none' : 'block' }}>{toursIds?.length > 0 ? `${toursIds?.length} selecionado` + (toursIds?.length > 1 ? 's' : '') : '                           '}</span>
              <span style={SpanSelect}><SelectSearch
                styles={customStyles}
                placeholder="Todos os produtos"
                menuIsOpen={openMenuProduct}
                onMenuOpen={() => setOpenMenuProduct(true)}
                onMenuClose={() => setOpenMenuProduct(false)}
                //options={tours}
                options={tours.map(tou => ({
                  value: tou?.value,
                  name: tou?.name?.toLowerCase(),
                  label: tou?.name?.toLowerCase(),
                }))}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option, //MenuList: SelectMenuButton
                }}
                allowSelectAll={true}
                showSelectedItems={false}
                width='220px'
                value={tour}
                onChange={(ev) => handleSelect(ev, 'tour')}
                name="product"
              /></span>
            </div>
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <div style={SearchStyle3}>
              <span style={LabelStyle}>Busque por vendedor</span>
              <span style={{ position: 'absolute', backgroundColor: 'white', display: openMenuSeller ? 'none' : 'block' }}>{sellersIds?.length > 0 ? `${sellersIds?.length} selecionado` + (sellersIds?.length > 1 ? 's' : '') : '                           '}</span>
              <span style={SpanSelect}><SelectSearch
                styles={customStyles}
                placeholder="Todos os vendedores"
                menuIsOpen={openMenuSeller}
                onMenuOpen={() => setOpenMenuSeller(true)}
                onMenuClose={() => setOpenMenuSeller(false)}
                //options={sellers}
                options={sellers.map(sel => ({
                  value: sel?.value,
                  name: sel?.name?.toLowerCase(),
                  label: sel?.name?.toLowerCase(),
                }))}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option, //MenuList: SelectMenuButton
                }}
                allowSelectAll={true}
                showSelectedItems={false}
                width='250px'
                value={seller}
                onChange={(ev) => handleSelect(ev, 'seller')}
                name="seller"
              />
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button
              textcolor="#fff"
              margintop="4px"
              padding="6px 30px"
              color="primary"
              size="large"
              width="150px"
              type="button"
              variant="text"
              className="cursor-pointer"
              onClick={() => getSeats(urlSeats, null, null)}
            >
              Filtrar
            </Button>
          </div>
        </div>
        <div className="p-3 mt-2" style={{ widht: '100%', position: 'relative', height: '1350px', overflowY: 'scroll', overflowX: 'scroll' }}>
          <Calendar {...props} reservations={reservations} cupos={seats} dates={tourDates} getSeats={getSeats} load={load} />
        </div>
      </div>
    </div>
    <ChangeStateModal
      open={openModal}
      close={() => setOpenModal(false)}
      onCreate={confirmPayment}
      status={status}
      selectedIdPayment={selectedIdPayment}
      setStatus={setStatus}
    />
  </>
}

export default Index;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '230px',
    color: state.selectProps.menuColor,
    padding: 10,
    paddingBottom: '10px',
    display: 'flex',
    fontSize: '14px',
    borderRadius: '20px',
    marginTop: '15px',
    marginLeft: '15px',
    boxShadow: '0 0 0 10px hsl(0deg 0% 0% / 4%), 0 4px 18px hsl(0deg 0% 0% / 10%)',
  }),

  option: (provided, state) => ({
    ...provided,
    background: '#fff',
    cursor: 'pointer',
    color: '#000',
    textTransform: 'capitalize',
  }),

  input: (provided, state) => {
    let y = state.isSelected && state.isDisabled ? '#SOME_COLOR' : ''
    return {
    ...provided,
    //marginLeft: '100px',
    marginLeft: '0px',
    zIndex: '-10',
    backgroundColor: 'white',
  }},

  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: '1px',
  }),
  
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: '10px'
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: 'white'
  }),

  control: (_, { selectProps: { width } }) => ({
    width: '230px',
    display: 'flex',
    fontSize: '15px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  },

  multiValue: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
}

const SearchStyle1 = {
  display: 'flex',
  alignItems: 'center',
  width: '230px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10007',
  position: 'relative'
}
const SearchStyle2 = {
  display: 'flex',
  alignItems: 'center',
  width: '230px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10004',
  position: 'relative'
}
const SearchStyle3 = {
  display: 'flex',
  alignItems: 'center',
  width: '230px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10003',
  position: 'relative'
}

const LabelStyle = {
  transform: 'translate(6px, -6px) scale(0.75)',
  top: '-22px',
  left: '0',
  position: 'relative',
  transformOrigin: 'top left',
  color: 'rgba(0, 0, 0, 0.54)',
  padding: '0px 4px',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1',
  letterSpacing: '0.00938em',
  background: '#fff',
  zIndex: '10006',
}

const SpanSelect = {
  marginLeft: '-250px',
}

const legendLanguage = {
  display: 'flex',
  padding: '1px',
  backgroundColor: '#E4E2FB',
  borderRadius: '50px',
  width: '20px',
  height: '20px',
  color: '#009976',
  justifyContent: 'center',
  marginInlineEnd: '2px'
}

const legendSeller = {
  display: 'flex',
  padding: '1px',
  backgroundColor: '#E4E2FB',
  borderRadius: '5px',
  width: '25px',
  height: '25px',
  color: '#009976',
  justifyContent: 'center',
  marginInlineEnd: '2px'
}

const legendHand = {
  display: 'flex',
  padding: '1px',
  backgroundColor: '#E4E2FB',
  borderRadius: '5px',
  width: '25px',
  height: '25px',
  color: '#009976',
  justifyContent: 'center',
  marginInlineEnd: '2px'
}