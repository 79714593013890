import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { apiClient } from '@services/api';
import TextField from '@components/TextField';
import Button from '@components/Button';
import Select from '@components/Select';
import SelectSearch from 'react-select';
import RadioButton from '@components/RadioButton';
import Checkbox from '@components/Checkbox';
import DatePicker from '@components/DatePicker';
import { format, isValid } from 'date-fns';
import { components } from "react-select";
import Modal from '@components/Modal';
import InputFile from '@components/InputFile'
import CircularProgress from '@material-ui/core/CircularProgress';

const Form = (props) => {
  const history = useHistory();
  const [result, setResult] = useState({})
  const [lastDate, setLastDate] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [exitDate, setExitDate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [templateUtalk, setTemplateUtalk] = useState({});
  const [customers, setCustomers] = useState([]);
  const [exits, setExits] = useState([]);
  const [customersIds, setCustomersIds] = useState([]);
  const [customersSelecteds, setCustomersSelecteds] = useState([]);
  const [nameCustomer, setNameCustomer] = useState(null);
  const [search, setSearch] = useState(null);
  const [load, setLoad] = useState(false);
  const [openMenuCustomer, setOpenMenuCustomer] = useState(false);
  const [confirmedVariables, setConfirmedVariables] = useState(true);
  const [countVariables, setCountVariables] = useState(0);
  const [messageVariables, setMessageVariables] = useState(["nome_cliente", "endereço", "consultor", "horário_saída", "os/referência", "nome_passeio", "data_passeio", "tipo_transfer", "horário_vôo", "nome_guia", "nome_motorista", "link_voucher"]);
  const customerVariables = ["nome_cliente", "endereço", "consultor"];
  const [showMessage, setShowMessage] = useState('');

  const radioButtonMessageType = [
    {
      value: 'reservas',
      label: 'Para reservas de um período'
    },
    {
      value: 'saida',
      label: 'Para clientes de uma saída'
    },
    {
      value: 'clientes',
      label: 'Para clientes selecionados'
    }
  ];


  const getTemplates = () => {
    apiClient.httpInstance.get('/templates?page=1&pageSize=500').then(response => {
      if (response.status === 200) {
        setTemplates(response.data.data);
      }
    }).catch(function (response) {
      console.log('Erro ao buscar templates')
    });
  }

  const getTemplateUtalk = (id) => {
    apiClient.httpInstance.get(`/central/template_utalk?template_id=${id}`).then(response => {
      if (response.status === 200) {
        let variables = []
        let content = response.data.content
        let startChar = "{{"
        let endChar = "}}"
        let options = 0
        let newArr = []
        let firstIn = 0
        let lastIn = 0
        for (let index = 0; index < content.length; index++) {
          if (startChar.indexOf(content.substr(index, 2)) >= 0) {
            firstIn = index
          }
          if (endChar.indexOf(content.substr(index, 2)) >= 0) {
            lastIn = index
            options++
            newArr.push(content.substr(firstIn + 2, lastIn - firstIn - 2))
          }
        }
        //setMessageVariables(newArr)
        setCountVariables(options)
        if (newArr.length > 0)
          setConfirmedVariables(false)
        setTemplateUtalk(response.data);
        setShowMessage(response.data?.content);
      }
    }).catch(function (response) {
      console.log('Erro ao buscar templates')
    });
  }

  const getCustomers = () => {
    apiClient.httpInstance.get(`/search_customers?q=${nameCustomer}&pageSize=1000`).then(response => {
      
      if (response.status === 200) {
        setCustomers(response.data);
      }
    }).catch(function (response) {
      console.log('Erro ao buscar clientes')
    });
  }

  const getExitCustomers = (exit, date) => {
    setLastDate(date)
    apiClient.httpInstance.get(`/exit/customers?exit_id=${exit}&date=${date}`).then(response => {
      if (response.status === 200) {
        setCustomers(response.data);
      }
    }).catch(function (response) {
      console.log('Erro ao buscar clientes')
    });
  }
  const getExitsDate = (date) => {
    apiClient.httpInstance.get(`/exit/exits_from_date?date=${date}`).then(response => {
      if (response.status === 200) {
        setExits(response.data);
      }
    }).catch(function (response) {
      console.log('Erro ao buscar saídas')
    });
  }

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    getTemplateUtalk(props.message.template_id);
  }, [props.message?.template_id]);

  useEffect(() => {
    if (props.message?.type === 'clientes') {
      getCustomers();
    }
  }, [nameCustomer]);

  const searchClients = (value) => {
    setLoad(true)
    setSearch(value)
    setTimeout(() => {
      setCustomers([]);
      setNameCustomer(value)
      clearTimeout()
      setLoad(false)
    }, 1500);
  }

  const changeVariables = async (value, type, index) => {
    if (type === 'include') {
      if (props.selectedVariables.length < countVariables) {
        let newArr = [...props.selectedVariables]
        newArr.push(value)
        props.setSelectedVariables(newArr)
        //Preenche mensagem a ser visualizada
        let lastIndex = newArr.indexOf(value);
        setShowMessage(showMessage.replace(`{{${lastIndex + 1}}}`, `${value.toUpperCase()}`))

        newArr = [...messageVariables]
        let idx = newArr.indexOf(value)
        newArr.splice(idx, 1)
        setMessageVariables(newArr)
        newArr.length === 0 ? setConfirmedVariables(true) : setConfirmedVariables(false)
      }
    }
    if (type === 'remove') {
      let newArr = [...props.selectedVariables]
      let idx = newArr.indexOf(value)

      if (idx + 1 == newArr.length) {
        //Preenche mensagem a ser visualizada
        setShowMessage(showMessage.replace(`${value.toUpperCase()}`, `{{${idx + 1}}}`))

        newArr.splice(idx, 1)
        props.setSelectedVariables(newArr)
        //let lastIndex = newArr.indexOf(value);

        newArr = [...messageVariables]
        newArr.push(value)
        setMessageVariables(newArr)
        newArr.length === 0 ? setConfirmedVariables(true) : setConfirmedVariables(false)
      }

    }

    if (type === 'include_link' && value) {
      //if (props.selectedVariables.length < countVariables) {
        let newArr = [...props.selectedVariables.filter(x => !x.match(value))]
        newArr.push(value)
        props.setSelectedVariables(newArr)
        //Preenche mensagem a ser visualizada
        let lastIndex = newArr.indexOf(value);
        setShowMessage(showMessage.replace(`{{${lastIndex + 1}}}`, `${value.toUpperCase()}`))

        newArr = [...messageVariables]
        let idx = newArr.indexOf(value)
        newArr.splice(idx, 1)
        setMessageVariables(newArr)
        newArr.length === 0 ? setConfirmedVariables(true) : setConfirmedVariables(false)
      //}
    }

  }

  const changeDate = (date, name) => {
    if (name === 'initial') {
      setInitialDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
      props.setInitialDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
    } else if (name === 'final') {
      setFinalDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
      props.setFinalDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
    } else if (name === 'exit') {
      if (date) {
        setExitDate(format(date, "yyyy-MM-dd"))
        props.setExitDate(format(date, "yyyy-MM-dd"))
        getExitCustomers('', format(date, "yyyy-MM-dd"))
        getExitsDate(format(date, "yyyy-MM-dd"))
      }
    }
  }

  const cleanOptions = () => {
    setExitDate(null)
    setInitialDate(null)
    setFinalDate(null)
    setExits([])
    setCustomersIds('')
    setCustomers([])
    props.setSelectedVariables([])
    setCustomersSelecteds([])
    props.setCustomersIds('')
  }

  const handleSelect = (ev) => {
    setLoad(true);
    let selecteds = []
    let newValues = ev.map((item) => {
      selecteds.push({ name: item.name, id: item.id })
      return item.value
    });

    if (ev.length === 0) {
      setCustomersIds('')
      props.setCustomersIds('')
      setCustomersSelecteds([])
    } else {
      setCustomersIds(newValues)
      props.setCustomersIds(newValues)
      setCustomersSelecteds(selecteds)
    }
    setLoad(false);
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="p-4 text-xl text-blue font-poppins flex flex-wrap">
          <div className="flex-1 space-y-1">
            <p>Selecione uma opção para envio</p>
            <RadioButton
              {...props}
              data={radioButtonMessageType}
              name="type"
              change={(ev) => {
                props.changeValueRadio(ev)
                cleanOptions()
                setCountVariables(0)
                setMessageVariables(["nome_cliente", "endereço", "consultor", "horário_saída", "os/referência", "nome_passeio", "data_passeio", "tipo_transfer", "horário_vôo", "nome_guia", "nome_motorista", "link_voucher"])
              }}
              value={props.message.type}
            />
          </div>
        </div>
        {props.message?.type === 'reservas' && <>
          <span className="p-4 text-blue font-poppins" style={{ fontSize: '12px' }}>A mensagem será encaminhada para os clientes das reservas do período indicado*</span>
          <div className="flex flex-wrap mb-4 space-x-3 p-4 text-blue font-poppins">
            <div className="flex-none">
              <DatePicker
                label="Data inicial"
                value={initialDate !== null ? `${initialDate} 00:00:00` : null}
                onChange={date => changeDate(date, 'initial')}
              />
            </div>
            <div className="flex-none">
              <DatePicker
                label="Data final"
                value={finalDate !== null ? `${finalDate} 00:00:00` : null}
                minDate={`${initialDate} 00:00:00`}
                onChange={date => changeDate(date, 'final')}
              />
            </div>
          </div></>}
        {props.message?.type === 'saida' && <>
          <span className="flex flex-wrap mb-4 mx-3 text-blue font-poppins" style={{ fontSize: '12px' }}>Verifique se todos os dados da saída foram preenchidos (data, horário, guia, motorista)</span>
        </>}
        {props.message?.type === 'saida' && <div className="flex flex-wrap mb-4 space-x-3">
          <div className="flex-none">
            <DatePicker
              label="Data da saída"
              value={exitDate !== null ? `${exitDate} 00:00:00` : null}
              onChange={date => changeDate(date, 'exit')}
            />
          </div>
          <div className="flex-none">
            <Select
              withoutAll
              label="Saída"
              value={props.message.exit_id}
              onChange={(ev) => {
                getExitCustomers(ev.target.value, lastDate)
                props.changeState(ev)
              }}
              name="exit_id"
              items={{
                field: {
                  value: 'id',
                  text: 'travel_name'
                },
                data: exits
              }}
              width='280px'
            />
          </div>
          <div style={SearchStyle3}>
            <span style={LabelStyle}>Selecione os clientes</span>
            <span style={{ position: 'absolute' }}>{customersIds?.length > 0 ? `${customersIds?.length} selecionado` + (customersIds?.length > 1 ? 's' : '') : '                           '}</span>
            <span style={SpanSelect}><SelectSearch
              styles={customStyles}
              placeholder="Todos os clientes"
              menuIsOpen={openMenuCustomer}
              onMenuOpen={() => setOpenMenuCustomer(true)}
              onMenuClose={() => setOpenMenuCustomer(false)}
              //options={sellers}
              options={customers.map(customer => ({
                value: customer?.id,
                name: customer?.name?.toLowerCase(),
                label: customer?.name?.toLowerCase(),
              }))}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option, //MenuList: SelectMenuButton
              }}
              allowSelectAll={true}
              showSelectedItems={false}
              width='280px'
              //value={customer}
              onChange={(ev) => handleSelect(ev)}
              name="customers"
            />
              {load && <div className="flex-1 text-center">
                <CircularProgress size={20} />
              </div>}
            </span>
          </div>
        </div>}
        {props.message?.type === 'clientes' && <div className="flex flex-wrap mb-4 space-x-3">
          <div className="flex-none">
            <TextField
              className="inputRounded"
              label="Busque por clientes"
              width="280px"
              variant="outlined"
              type="text"
              name="search"
              onChange={(ev) => searchClients(ev.target.value)}
            />
          </div>
          <div style={SearchStyle3}>
            <span style={LabelStyle}>Selecione os clientes</span>
            <span style={{ position: 'absolute' }}>{customersIds?.length > 0 ? `${customersIds?.length} selecionado` + (customersIds?.length > 1 ? 's' : '') : '                           '}</span>
            <span style={SpanSelect}><SelectSearch
              styles={customStyles}
              placeholder="Selecione"
              menuIsOpen={openMenuCustomer}
              onMenuOpen={() => setOpenMenuCustomer(true)}
              onMenuClose={() => setOpenMenuCustomer(false)}
              //options={sellers}
              options={customers.map(customer => ({
                value: customer?.id,
                name: customer?.name?.toLowerCase(),
                label: customer?.name?.toLowerCase(),
              }))}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option, //MenuList: SelectMenuButton
              }}
              allowSelectAll={true}
              showSelectedItems={false}
              width='280px'
              //value={customer}
              onChange={(ev) => handleSelect(ev)}
              name="customers"
            />
              {load && <div className="flex-1 text-center">
                <CircularProgress size={20} />
              </div>}
            </span>
          </div>
        </div>}
        <div className="p-4 text-xl text-blue font-poppins flex">Modelo da mensagem</div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <Select
              withoutAll
              label="Template"
              value={props.message.template_id}
              disabled={customersSelecteds?.length || (initialDate && finalDate) ? false : true}
              onChange={(ev) => {
                setTemplateUtalk({})
                setShowMessage('')
                props.changeState(ev)
                props.setSelectedVariables([])
                setMessageVariables(["nome_cliente", "endereço", "consultor", "horário_saída", "os/referência", "nome_passeio", "data_passeio", "tipo_transfer", "horário_vôo", "nome_guia", "nome_motorista", "link_voucher"])
              }}
              name="template_id"
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: templates
              }}
              required
            />
          </div>
        </div>
        {templateUtalk?.content && <>
          <div className="p-4 text-blue font-poppins" style={{ fontSize: '13px' }}>
            <div className="flex-auto">
              {showMessage}
            </div>
          </div>
          <div className="p-4 text-blue font-poppins" style={{ fontSize: '13px' }}>
            {countVariables > 0 ? <span>Selecionadas (remova a partir da última): </span> : ''}
            {props.selectedVariables.map((value, index) => {
              return (
                <span className='space-x-4 px-2 mx-1 items-center justify-center rounded-full text-white cursor-pointer' style={{ background: '#009C86E5' }} onClick={() => changeVariables(value, 'remove', index)}>{value}</span>)
            })}
          </div>
          {(messageVariables && messageVariables.length > 0 && countVariables > 0) ? <div className="p-4 text-blue font-poppins" style={{ fontSize: '13px' }}>
            <p className='mb-2'>Selecione os seguintes valores, na ordem, para substituir as variáveis na mensagem</p>
            {props.message?.type === 'clientes' ?
              messageVariables.map((value, index) => {
                if (customerVariables.includes(value)) {
                  return (
                    <span className='space-x-4 px-2 mx-1 items-center justify-center rounded-full text-white cursor-pointer' style={{ background: '#009C86E5' }} onClick={() => changeVariables(value, 'include', index)}>{value}</span>)
                }
              })
              :
              messageVariables.map((value, index) => {
                return (
                  <span className='space-x-4 px-2 mx-1 items-center justify-center rounded-full text-white cursor-pointer' style={{ background: '#009C86E5' }} onClick={() => changeVariables(value, 'include', index)}>{value}</span>)
              })
            }

            <div className="flex flex-row my-4  space-x-3">
              <div className="flex-1">
                <span>Link</span>
                <TextField
                  className="inputRounded"
                  label="Link"
                  variant="outlined"
                  type="text"
                  name="link"
                  //value={props.tour.link}
                  onChange={(ev) => {props.changeState(ev) }}
                  onBlur={(e) => changeVariables(e.target.value, 'include_link', 1)}
                />
              </div>
              <div className="flex-1">
                <span>Documento</span>
                <TextField
                  className="inputRounded"
                  label=""
                  variant="outlined"
                  type="file"
                  name="document"
                  accept="application/pdf"
                //value={props.tour.name}
                //onChange={(ev) => props.changeState(ev)}
                />
              </div>
              <div className="flex-1">
                <span>Imagem</span>
                <TextField
                  className="inputRounded"
                  label=""
                  variant="outlined"
                  type="file"
                  name="image"
                  accept="image/*"
                //value={props.tour.name}
                //onChange={(ev) => props.changeState(ev)}
                />
              </div>
              <div className="flex-1">
                <span>Vídeo</span>
                <TextField
                  className="inputRounded"
                  label=""
                  variant="outlined"
                  type="file"
                  name="video"
                  accept="video/*"
                //value={props.tour.name}
                //onChange={(ev) => props.changeState(ev)}
                />
              </div>
            </div>

            {/*<div className="" style={{ fontSize: '13px' }}>
              <InputFile changeFile={props.changeFile} banner={props.tour.banner} />
            </div>*/}

          </div> : ''}
        </>}

      </div>

      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
          disabled={props.selectedVariables.length && props.selectedVariables.length == countVariables ? false : true}
        >
          {!props.isLoading ? 'Enviar' : <CircularProgress color='inherit' size={15} />}
        </Button>
      </div>
    </form>
    <Modal open={props.openModal} style={{ width: '90%', maxWidth: '450px' }}>
      <div className="flex mt-4">
        <p>Envio confirmado para:</p>
      </div>
      {customersSelecteds.map(customer => (
        <div className="flex mt-4">
          <p>- {customer.name.toUpperCase()}</p>
        </div>
      ))}
      < div className="justify-center mt-4 md:flex w-100" >
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={(ev) => history.push('/admin/central/mensagens')}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  </>
}

export default Form

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '320px',
    color: state.selectProps.menuColor,
    padding: 10,
    paddingBottom: '10px',
    display: 'flex',
    fontSize: '14px',
    borderRadius: '20px',
    marginTop: '15px',
    marginLeft: '-9px',
    boxShadow: '0 0 0 10px hsl(0deg 0% 0% / 4%), 0 4px 18px hsl(0deg 0% 0% / 10%)',
  }),

  option: (provided, state) => ({
    ...provided,
    background: '#fff',
    cursor: 'pointer',
    color: '#000',
    textTransform: 'capitalize',
  }),

  input: (provided, state) => ({
    ...provided,
    marginLeft: '125px',
  }),

  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: '2px',
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: 'white'
  }),

  control: (_, { selectProps: { width } }) => ({
    width: '300px',
    display: 'flex',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  },

  multiValue: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
}

const SearchStyle3 = {
  display: 'flex',
  alignItems: 'center',
  width: '320px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10003'
}

const LabelStyle = {
  transform: 'translate(6px, -6px) scale(0.75)',
  top: '-22px',
  left: '0',
  position: 'relative',
  transformOrigin: 'top left',
  color: 'rgba(0, 0, 0, 0.54)',
  padding: '0px 4px',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1',
  letterSpacing: '0.00938em',
  background: '#fff',
  zIndex: '10005',
}

const SpanSelect = {
  marginLeft: '-250px',
}