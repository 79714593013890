import BreadCrumbs from '@components/Breadcrumbs';
import Select from '@components/Select';
import Table from '@components/Table';
import TextField from '@components/TextField';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { apiClient } from '@services/api';

const Index = (props) => {
  document.title = `Lista de clientes - ${process.env.REACT_APP_NAME}`
  const [rows] = useState([]);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [q, setQ] = useState(null);

  // const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);

  const [type, setType] = useState(null);
  const [search, setSearch] = useState(0);
  const [loading, setLoading] = useState(false);

  const searchValue = (value) => {
    setLoading(true)
    setSearch(value)
    setQ(value + "a")

    setLoading(false)
    setTimeout(() => {
      setQ(value)
      clearTimeout()
    }, 1500);


  }

  /* const getCompanies = () => {
    apiClient.httpInstance.get(`/companies?pageSize=500`).then(response => {
      if (response.status === 200) {
        let companies = response.data.data.sort(function (a, b) {
          return a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;
        })

        setCompanies(companies);
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  } */

  /* useEffect(() => {
    getCompanies();
  }, []); */

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const typeOptions = [
    {
      value: 'combo',
      name: 'Combo'
    },
    {
      value: 'tour',
      name: 'Passeio'
    },
    {
      value: 'transfer',
      name: 'Transaldo'
    },

  ]
  return <>
    <div className="px-7">
      <div className="flex items-center justify-start mt-2 text-2xl text-blue mb-3">
        <Icon className="mr-2">
          people
        </Icon>
        <span>Clientes</span>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center md:space-x-3">
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
            <TextField
              className="inputRounded"
              label="Busque por cliente"
              width="280px"
              variant="outlined"
              type="text"
              name="search"
              onChange={(ev) => searchValue(ev.target.value)}
            />
          </div>
          {/* <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <Select
              label="Filtrar por empresa"
              defaultValue="all"
              gender="f"
              width="220px"
              onChange={(e) => setCompany(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'surname',
                  key: 'key'
                },
                data: companies
              }}
            ></Select>
          </div> */}
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <Select
              label="Buscar por"
              width="200px"
              defaultValue="all"
              onChange={(e) => setType(e.target.value)}
              items={{
                field: {
                  value: 'value',
                  text: 'name'
                },
                data: typeOptions
              }}
            />
          </div>
        </div>
      </div>

      <Table
        checkLoading={true}
        path="/customers"
        params={{
          q, type, company
        }}
        columns={[
          {
            headerName: 'Clientes',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            width: windowSize.current[0] < 700 ? 300 : 500
          },
          /* {
            headerName: 'Empresa',
            headerClassName: 'super-app-theme--header',
            field: 'company',
            width: windowSize.current[0] < 700 ? 120 : 150
          }, */
          {
            headerName: 'Telefone',
            headerClassName: 'super-app-theme--header',
            field: 'phone',
            width: windowSize.current[0] < 700 ? 200 : 250
          },
          {
            headerName: 'Reserva',
            headerClassName: 'super-app-theme--header',
            field: 'reference_type',
            width: windowSize.current[0] < 700 ? 130 : 150,
            renderCell: (params) => {
              const row = params.row;
              const selectType = {
                tour: {
                  bg: '#D8212FCC',
                  text: 'Passeio'
                },
                transfer: {
                  bg: '#4480A8E5',
                  text: 'Traslado'
                },
                translate: {
                  bg: '#4480A8E5',
                  text: 'Traslado'
                },
                'translate-out': {
                  bg: '#4480A8E5',
                  text: 'Out'
                },
                'translate-in': {
                  bg: '#009C86E5',
                  text: 'In'
                }
              }

              return <div className="flex items-center pr-4 space-x-5">
                {row.type &&
                  <>
                    {row.type in selectType
                      ?
                      <div className="flex items-center justify-center rounded-full h-8 w-24 text-white" style={{ background: selectType[row.type].bg }}>
                        {selectType[row.type].text}
                      </div>
                      :
                      row.type
                    }
                  </>
                }
              </div>;
            }
          },
          {
            headerName: 'Próximo passeio',
            headerClassName: 'super-app-theme--header',
            field: 'next_date',
            width: windowSize.current[0] < 700 ? 180 : 200,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                {row.next_date ?
                  <Moment format="DD/MM/YYYY" date={row.next_date} /> :
                  <Moment format="DD/MM/YYYY" date={row.last_date} className='text-gray' />
                }
              </div>);
            }
          },
          {
            headerName: 'Pagamento',
            headerClassName: 'super-app-theme--header',
            field: 'payment_status',
            width: windowSize.current[0] < 700 ? 180 : 210,
            renderCell: (params) => {
              const row = params.row;
              const status = {
                paid: {
                  text: 'Realizado',
                  icon: 'check_circle'
                },
                accomplished: {
                  text: 'Realizado',
                  icon: 'check_circle'
                },
                cancelled: {
                  text: 'Pendente',
                  icon: 'do_disturb_on'
                },
                pending: {
                  text: 'Pendente',
                  icon: 'do_disturb_on'
                },
                partial: {
                  text: 'Parcial',
                  icon: 'access_time'
                }
              }
              return <span className="flex items-center">
                {row.payment_status &&
                  <>{status[row.payment_status]?.text} <Icon className="ml-2">{status[row.payment_status]?.icon}</Icon> </>
                }
              </span>;
            }
          },
        ]}
        rows={rows} />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Index);

