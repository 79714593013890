import ClientForm from './form/ClientForm';
import { useEffect, useState } from 'react';
import { apiClient } from '@services/api';

const ClientUpdatePartial = (props) => {
    const [urlLink, setUrlLink] = useState(null)
    const url = `/presales/${props.presales_id}`
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);


    const getSalesDetails = url => {
        apiClient.httpInstance.get(url).then(response => {
            if (response.status === 200) {
                if (response.data.email) {
                    setClient({
                        ...client,
                        id: response.data.customer_id,
                        email: response.data.email,
                        name: response.data.name,
                        phone: response.data.phone,
                        document: response.data.document
                    });

                    //props.handle(client);
                } else {
                    getClientById(response.data.customer_id)
                    setLoading(false);
                }
            }
        })
            .catch(error => {
                if (error) {
                    setError(true);
                    setErrorMessage("Esse orçamento não existe");
                    setTimeout(() => { setError(false) }, 4000);
                    setTimeout(() => { setErrorMessage(null) }, 4000);
                    setLoading(true);
                }
            });
    }
    const getClientById = (id) => {
        apiClient.httpInstance.get(`customers/${id}`).then(response => {
            if (response.status === 200) {
                setClient({
                    ...client,
                    id: response.data.customer_id,
                    email: response.data.email,
                    name: response.data.name,
                    phone: response.data.phone,
                    document: response.data.document
                });

                //props.handle(client);
                setLoading(false);
            }
        })
            .catch(error => {
                if (error) {
                    setError(true);
                    setErrorMessage("Cliente não encontrado");
                    setTimeout(() => { setError(false) }, 4000);
                    setTimeout(() => { setErrorMessage(null) }, 4000);
                }
            });
    }

    const [client, setClient] = useState({
        addresses: [{
            id: '',
            region_id: '',
            region_name: '',
            customer_id: '',
            hotel: '',
            hotel_address: '',
            room_number: ''
        },
        {
            id: '',
            region_id: '',
            region_name: '',
            customer_id: '',
            hotel: '',
            hotel_address: '',
            room_number: ''
        }],
        customer_id: null,
        email: null,
        document: null,
        name: null,
        phone: null,
        hotel_name: null,
        hotel_address: null,
        room_number: null,
        customer_language: null,
        observations: null,
        havePartner: false,
    });

    const [emailValid, setEmailValidate] = useState(false);
    const changeClient = (ev) => {
        let value = ev.target.value
        setClient({
            ...client,
            [ev.target.name]: value
        })
    }

    useEffect(() => {
        props.handle(client);
    }, [client]);

    const changeClientByPartner = (data) => {
        const clientData = {
            ...client,
            partner: data.partner,
            partnerEmail: data.partnerEmail,
            partner_seller_id: data.partner_seller_id,
            partner_type: data.partner_type
        }
        setClient(clientData);
    }

    const onlyLetterAndNumber = (e) => {
        e.preventDefault();
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const value = e.target.value
        if (value.match(regex)) {
            setClient({
                ...client,
                document: value
            })
        } else if (value === "") {
            setClient({
                ...client,
                document: null
            })
        }
    }

    const changeClientCheckbox = (ev) => {
        let value = null
        if (ev.target.value === "true") {
            value = true
        } else {
            value = false
        }

        setClient({
            ...client,
            partner: null,
            [ev.target.name]: value
        });
    }

    const changeClientAddress = (ev) => {
        let value = ev.target.value
        let addresses = client.addresses

        if (ev.target.index !== undefined) {
            addresses[ev.target.index][ev.target.name] = value

            if (ev.target.region_id && ev.target.region_id !== '') {
                addresses[ev.target.index]['region_id'] = ev.target.region_id
            }
            if (ev.target.region_name && ev.target.region_name !== '') {
                addresses[ev.target.index]['region_name'] = ev.target.region_name
            }
        }

        setClient({
            ...client,
            addresses: addresses
        })
    }

    const changeClientByEmail = (data) => {
        if (data.addresses.length === 0) {
            data.addresses.push(
                {
                    id: '',
                    region_id: '',
                    region_name: '',
                    customer_id: '',
                    hotel: '',
                    hotel_address: '',
                    room_number: ''
                },
                {
                    id: '',
                    region_id: '',
                    region_name: '',
                    customer_id: '',
                    hotel: '',
                    hotel_address: '',
                    room_number: ''
                },
            )
        }
        if (data.addresses.length === 1) {
            data.addresses.push(
                {
                    id: '',
                    region_id: '',
                    region_name: '',
                    customer_id: '',
                    hotel: '',
                    hotel_address: '',
                    room_number: ''
                },
            )
        }
        const clientData = {
            ...client,
            addresses: data.addresses,
            customer_id: data.customer_id,
            customer_language: data.customer_language,
            document: data.document,
            email: data.email,
            name: data.name,
            hotel_name: data.hotel_name,
            hotel_address: data.hotel_address,
            room_number: data.room_number,
            observations: data.observations,
            phone: data.phone,
            partner: data.partner,
            partnerEmail: data.partnerEmail,
            havePartner: data.havePartner,
            partner_seller_id: data.partner_seller_id,
            partner_type: data.partner_type
        }
        setClient(clientData);
    }

    const changeObservations = (ev) => {
        let value = ev.target.value

        setClient({
            ...client,
            ['observations']: value
        });
    }

    useEffect(() => {
        getSalesDetails(`/presales/${props.presales_id}`);
    }, [props.presales_id]);
    
    useEffect(()=>{
        getSalesDetails(`/presales/${props.presales_id}`);
    },[props.changed])
    
    return <>
        <ClientForm
            client={client}
            clientEmail={client.email}
            changeClientByEmail={changeClientByEmail}
            changeClientByPartner={changeClientByPartner}
            changeClient={changeClient}
            changeClientAddress={changeClientAddress}
            changeObservations={changeObservations}
            onlyLetterAndNumber={onlyLetterAndNumber}
            changeClientCheckbox={changeClientCheckbox}
            changeSetEmail={setEmailValidate}
        />
    </>
}

export default ClientUpdatePartial;