import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import UpateTableBody from './partials/UpdateBodyData';
import { apiClient } from '@services/api';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ChooseProductEditModal from './product/ChooseProductEditModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClientUpdatePartial from './partials/ClientUpdatePartial';
import { tr } from 'date-fns/locale';
import { set } from 'date-fns';

const Update = (props) => {
  document.title = `Editar detalhes do orçamento - ${process.env.REACT_APP_NAME}`

  const { presales_id } = useParams();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [reservationsToShow, setReservationsToShow] = useState([]);
  const [reservationsToRemove, setReservationsToRemove] = useState([]);
  const [reservationsToAdd, setReservationsToAdd] = useState([]);
  const [changed, setChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let disabledButton = false;
  let client = useRef({
    addresses: [{
      id: '',
      region_id: '',
      region_name: '',
      customer_id: '',
      hotel: '',
      hotel_address: '',
      room_number: ''
    },
    {
      id: '',
      region_id: '',
      region_name: '',
      customer_id: '',
      hotel: '',
      hotel_address: '',
      room_number: ''
    }],
    customer_id: null,
    email: null,
    document: null,
    name: null,
    phone: null,
    hotel_name: null,
    hotel_address: null,
    room_number: null,
    customer_language: null,
    observations: null,
    havePartner: false,
  });
  const [reserve, setReserve] = useState({
    payment_status: null,
    payment_type: null,
    payment_method: null,
    customer_paid_amount: null,
    payment_proof: null,
    user_id: null,
    seller_name: null,
    seller_email: null,
  })
  const [isEditing, setIsEditing] = useState(false)

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos'
    },
    {
      title: `Orçamento `,
      active: true,
      route: `/admin/orcamentos/detalhe/${presales_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const handle = (value) => {
    client = value
  }



  const getReservations = () => {
    if (reservations) {
      return reservations;
    }
    return [];
  }

  const getReservationsToAdd = () => {
    if (reservationsToAdd) {
      return reservationsToAdd;
    }
    return [];
  }


  const editSale = () => {

    const data = {
      customer_id: client.id,
      customer_email: client.email,
      customer_name: client.name,
      customer_document: client.document,
      phone: client.phone,
      customer_language: client.customer_language,
      customer_observations: client.observations,
      pre_sale_id: reserve.id,
      seller_name: reserve.seller_name,
      seller_email: reserve.seller_email,
      user_id: reserve.user_id,
      reservations: reservations,
      reservationsToRemove: reservationsToRemove,
      reservationsToAdd: reservationsToAdd
    }
    setIsLoading(true);
    apiClient
      .httpInstance.put(`/presales/${presales_id}/update-values`, data)
      .then(response => {
        setIsLoading(false);
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/orcamentos/detalhe/${presales_id}`)
        }
      })
      .catch(error => {
        if (error) {
          setIsLoading(false);
          setChanged(!changed)
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
        }
      })
  }

  const pushBudgets = (travelInfo, isCombo = false, isTour = false, isTransfer = false) => {
    if (travelInfo) {
      if (isCombo) {
        travelInfo.map((t) => {
          reservationsToAdd.push(buildItem(t, isCombo, isTour, isTransfer));
        });

        if (travelInfo.length > 0) {
          reservationsToShow.push(buildItem(travelInfo[0], true, false, false, false,));
        }

        setReservationsToAdd(reservationsToAdd);
        setReservationsToShow(reservationsToShow);
      } else {
        var item = buildItem(travelInfo, isCombo, isTour, isTransfer)

        reservationsToAdd.push(item);
        reservationsToShow.push(item);
        setReservationsToAdd(reservationsToAdd);
        setReservationsToShow(reservationsToShow);
        setChanged(!changed)
      }
      setOpen(false);
    }
  }



  const buildItem = (travelInfo, isCombo = false, isTour = false, isTransfer = false) => {
    return {
      travel_id: travelInfo.travel_id,
      operational_cost: travelInfo.operational_cost ? travelInfo.operational_cost.toString() : '0',
      travel_type: travelInfo.travel_type ? travelInfo.travel_type : travelInfo.type,
      transfer_type: isTransfer ? travelInfo.transfer_type : null,
      customer_email: "",
      cupos: !isTransfer ? travelInfo.availables_cupos : travelInfo.quantity,
      is_group: travelInfo.is_group,
      tour_date: null,
      adults_total: travelInfo.adults_total ? travelInfo.adults_total : 0,
      adults_total_amount: 0,
      adults_operational_cost: travelInfo.operational_cost ? travelInfo.operational_cost.toString() : '0',
      adults_discount: travelInfo.adult_discount ? travelInfo.adult_discount.toString() : '0',
      kids_total: 0,
      kids_total_amount: 0,
      kids_operational_cost: travelInfo.kid_operational_cost ? travelInfo.kid_operational_cost.toString() : '0',
      kids_discount: travelInfo.kids_discount ? travelInfo.kids_discount.toString() : '0',
      discount_total_amount: 0,
      combo_id: isCombo && travelInfo.combo_id ? travelInfo.combo_id : null,
      total_amount: 0,
      commission: travelInfo.commission,
      discount: travelInfo.discount,
      flight_code: isTransfer ? travelInfo.flight_code : null,
      flight_time: isTransfer ? travelInfo.tour_date : null,
      fixed_commission_dolars: null,
      fixed_commission_pesos: null,
      margin_discount: null,
      mode_transfer: null,
      modality: null,
      quantity: travelInfo.quantity ? travelInfo.quantity : 0,
      status: travelInfo.status == true ? "1" : "0",
      total: 0,
      value: 0,
      value_cost: null,
      value_pesos: null,
      value_pesos_cost: null,
      value_to: null,
      value_of: null,
      pre_sale_id: presales_id,
      status_discount_approve: null,
      justify_discount: null,
      discount_total_sale: null,
      price: travelInfo.price,
      travel_name: travelInfo.travel_name,
      region_id: travelInfo.region_id,
      banner: travelInfo.banner,
      type: travelInfo.type
    }
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {
      isLoading ? <div class="flex items-center justify-center h-screen">
        <div class="bg-gray-300 p-4">
          <CircularProgress color='inherit' size={100} />
        </div>
      </div> :
        <div className="px-7 pt-6">
          {error && (
            <Alert className="mb-5" variant="filled" severity="error">
              {errorMessage}
            </Alert>
          )}
          <ChooseProductEditModal
            {...props}
            open={open}
            close={() => setOpen(false)}
            pushBudgets={pushBudgets}
            getReservations={getReservations}
            getReservationsToAdd={getReservationsToAdd}
            reservations
          />
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <div className="flex justify-end mb-4 space-x-5">

              <Button
                textcolor="#212121"
                padding="6px 15px"
                width="200"
                variant="outlined"
                onClick={() => history.push(`/admin/orcamentos/detalhe/${presales_id}`)}
                bgColor="transparent"
              >
                <Icon className="mr-2">
                  arrow_back
                </Icon>
                Cancelar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                width="260px"
                onClick={() => editSale()}
                customcolor={props.theme.color}
              >
                Salvar alterações
              </Button>
            </div>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <UpateTableBody
              {...props}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              reserve={reserve}
              setReserve={setReserve}
              presales_id={presales_id}
              reservations={reservations}
              setReservations={setReservations}
              reservationsToShow={reservationsToShow}
              setReservationsToShow={setReservationsToShow}
              reservationsToRemove={reservationsToRemove}
              setReservationsToRemove={setReservationsToRemove}
              reservationsToAdd={reservationsToAdd}
              setReservationsToAdd={setReservationsToAdd}
              getReservationsToAdd={getReservationsToAdd}
              open={open}
              setOpen={setOpen}
            />
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <ClientUpdatePartial {...props} presales_id={presales_id} handle={handle} changed={changed} />
          </div>
          <div className="flex justify-end mb-4 space-x-5">
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/orcamentos/detalhe/${presales_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
            <Button
              textcolor="#fff"
              padding="6px 15px"
              width="260px"
              onClick={() => editSale()}
              customcolor={props.theme.color}
            >
              Salvar alterações
            </Button>
          </div>
        </div>}
  </>
}

export default Update;