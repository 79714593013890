import * as React from 'react'
import { apiClient } from '@services/api';
import DatePicker from '@components/DatePicker';
import SalesReserveTable from './SalesReserveTable';
import Select from '@components/Select';
import Button from '@components/Button';
import { format, isValid } from 'date-fns';

import { yearIntervalOptions } from '../../../../../../consts/yearIntervalOptions';

import { monthOptions } from '../../../../../../consts/monthOptions';

class Content extends React.Component {
  render() {
    const seller = this.props.seller
    const from_date = this.props.from_date
    const to_date = this.props.to_date
    const status_reservation = this.props.status_reservation
    const get_data_reservations = this.props.get_data_reservations
    const status_commission = this.props.status_commission
    const type = this.props.type
    const id = this.props.id
    const year = this.props.year
    const month = this.props.month
    // const company = this.props.company

    return <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue font-poppins">
        <span>{seller}</span>
      </div>
      <SalesReserveTable
        path={`/sellers/get/all_sellers/${type}/${id}/sales`}
        type={type}
        id={id}
        permission={this.props.theme.permission}
        params={{
          from_date: from_date,
          to_date: to_date,
          year: year,
          month: month,
          status_reservation: status_reservation,
          status_commission: status_commission,
          travelType: 'transfer',
          // company: company
        }}
        get_data_reservations={get_data_reservations}
        print={true}
      />
    </div>
  }
}

const Transfers = ({
  id, componentRef, type, sellerPrint, ...props
}) => {

  const [pre_status_reservation, setPreStatusReservation] = React.useState('')
  const [pre_status_commission, setPreStatusCommission] = React.useState('')
  const [pre_month, setPreMonth] = React.useState('')
  const [pre_year, setPreYear] = React.useState('')
  const [status_reservation, setStatusReservation] = React.useState('')
  const [get_data_reservations, setGetDataReservations] = React.useState('')
  const [status_commission, setStatusCommission] = React.useState('')
  const [timeInterval, setTimeInterval] = React.useState('')
  const [month, setMonth] = React.useState('')
  const [year, setYear] = React.useState('')
  const [from_date, setFromDate] = React.useState(null)
  const [to_date, setToDate] = React.useState(null)
  const [showTableSalesReserve, setShowTableSalesReserve] = React.useState(false)
  const [companies, setCompanies] = React.useState([]);
  // const [company, setCompany] = React.useState(null);
  const { urlCompanies } = '/companies';

  const statusReserveOptions = [
    {
      value: 'pending',
      title: 'Pagamento pendente'
    },
    {
      value: 'accomplished',
      title: 'Pagamento realizado'
    },
    {
      value: 'canceled',
      title: 'Cancelado'
    }
  ]

  const statusCommissionOptions = [
    {
      value: 'pending',
      title: 'Pendente'
    },
    {
      value: 'sended',
      title: 'Habilitado'
    },
    {
      value: 'canceled',
      title: 'Cancelado'
    }
  ]

  const timeIntervalOptions = [
    {
      value: 'per_month',
      title: 'Por mês'
    },
    {
      value: 'per_period',
      title: 'Por período'
    }
  ]

  const changeTimeInterval = (value) => {
    setFromDate(null)
    setToDate(null)
    setMonth('')
    setYear('')
    setPreMonth('')
    setPreYear('')
    setTimeInterval(value)
  }

  const changeFromDate = (date) => {
    setFromDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const changeToDate = (date) => {
    setToDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const generateReport = () => {
    setShowTableSalesReserve(false)
    setStatusReservation(pre_status_reservation)
    setStatusCommission(pre_status_commission)
    setMonth(pre_month)
    setYear(pre_year)
    setFromDate(from_date)
    setToDate(to_date)
    setShowTableSalesReserve(true)
    setGetDataReservations(true)
  }

  /* const getCompanies = url => {
    apiClient.httpInstance.get(`/companies?pageSize=500`).then(response => {
      if (response.status === 200) {
        let companies = response.data.data.sort(function (a, b) {
          return a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;
        })

        setCompanies(companies);

        if (process.env.REACT_APP_COMPANY_KEY == 'full-tour-chile') {
          setCompany(3)
        } else if (process.env.REACT_APP_COMPANY_KEY == 'tour-go') {
          setCompany(4)
        }
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  } */

  /* React.useEffect(() => {
    getCompanies(urlCompanies);
  }, []); */

  return (
    <>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center space-x-3">
          {/* <div className="flex-none">
            <Select
              label="Filtrar por empresa"
              value={company || "all"}
              gender="f"
              width="220px"
              onChange={(e) => setCompany(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'surname',
                  key: 'key'
                },
                data: companies
              }}
            ></Select>
          </div> */}
          <div className="flex-none">
            <Select
              label="Status da reserva"
              width="200px"
              defaultValue=""
              onChange={(e) => {
                setPreStatusReservation(e.target.value)
                setShowTableSalesReserve(false)
              }
              }
              items={{
                field: {
                  value: 'value',
                  text: 'title'
                },
                data: statusReserveOptions
              }}
            />
          </div>
          <div className="flex-none">
            <Select
              label="Status da comissão"
              width="200px"
              defaultValue=""
              onChange={(e) => {
                setPreStatusCommission(e.target.value)
                setShowTableSalesReserve(false)
              }}
              items={{
                field: {
                  value: 'value',
                  text: 'title'
                },
                data: statusCommissionOptions
              }}
            />
          </div>
          <div className="flex-none">
            <Select
              label="Intervalo de tempo"
              width="200px"
              defaultValue=""
              onChange={(e) => {
                changeTimeInterval(e.target.value)
                setShowTableSalesReserve(false)
              }}
              items={{
                field: {
                  value: 'value',
                  text: 'title'
                },
                data: timeIntervalOptions
              }}
            />
          </div>
          {timeInterval === 'per_month' && (
            <div className="flex-none">
              <Select
                label="Filtre por ano"
                width="200px"
                defaultValue=""
                onChange={(e) => {
                  setPreYear(e.target.value)
                  setShowTableSalesReserve(false)
                }
                }
                items={{
                  field: {
                    value: 'value',
                    text: 'title'
                  },
                  data: yearIntervalOptions
                }}
              />
            </div>
          )}
          {timeInterval === 'per_month' && (
            <div className="flex-none">
              <Select
                label="Filtre por mês"
                width="200px"
                defaultValue=""
                onChange={(e) => {
                  setPreMonth(e.target.value)
                  setShowTableSalesReserve(false)
                }}
                items={{
                  field: {
                    value: 'value',
                    text: 'title'
                  },
                  data: monthOptions
                }}
              />
            </div>
          )}
          {timeInterval === 'per_period' && (
            <div className="flex-none">
              <DatePicker
                width="170px"
                label="Data inicial"
                value={from_date !== null ? `${from_date} 00:00:00` : null}
                onChange={date => {
                  changeFromDate(date)
                  setShowTableSalesReserve(false)
                }}
              />
            </div>
          )}
          {timeInterval === 'per_period' && (
            <div className="flex-none">
              <DatePicker
                width="170px"
                label="Data final"
                value={to_date !== null ? `${to_date} 00:00:00` : null}
                minDate={`${from_date} 00:00:00`}
                onChange={date => {
                  changeToDate(date)
                  setShowTableSalesReserve(false)
                }}
              />
            </div>
          )}
          <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            width="150px"
            disabled={pre_status_reservation != '' && timeInterval != '' || month != '' ? false : true}
            onClick={() => generateReport()}
          >
            Gerar relatório
          </Button>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <Content
          {...props}
          ref={componentRef}
          from_date={from_date}
          to_date={to_date}
          status_reservation={status_reservation}
          status_commission={status_commission}
          type={type}
          id={id}
          permission={props.theme.permission}
          seller={sellerPrint}
          year={year}
          month={month}
          get_data_reservations={get_data_reservations}
        />
      </div>
      {showTableSalesReserve ?
        <SalesReserveTable
          path={`/sellers/get/all_sellers/${type}/${id}/sales`}
          type={type}
          id={id}
          permission={props.theme.permission}
          params={{
            from_date: from_date,
            to_date: to_date,
            year: year,
            month: month,
            status_reservation: status_reservation,
            status_commission: status_commission,
            travelType: 'transfer',
            // company: company
          }}
          get_data_reservations={get_data_reservations}
        />
        :
        ''
      }
    </>
  )
}

export default Transfers